.CallSheetPdfTemplate-modal {
  &.ModalPopUp-portal {
    overflow-y: scroll;
    height: 100%;
    align-items: unset;
    background-color: rgba(0, 0, 0, 0.65);
    top: 0;
  }
  .ModalPopUp-close {
    position: fixed;
    right: 175px;
    top: 2.5rem;
    z-index: 3;
  }

  .ModalPopUp-container {
    width: auto;
  }

  .ModalPopUp-backdrop {
    background-color: transparent;
  }
  .container {
    margin-top: 84px;
    z-index: 2;
  }
  .content {
    padding-bottom: 3rem;
    background-color: transparent;
  }
}

.CallSheetPdfTemplate {
  height: 100%;
  position: relative;
  top: 0;
  // background-color: var(--white-0);
  // &.isInvisible {
  //   z-index: -3;
  //   position: absolute;
  // }
  .CallSheetTemplate {
    min-height: 1300px;
    border: none;
    box-shadow: none;
  }
  &-buttons {
    position: absolute;
    display: flex;
    gap: 0 16px;
    margin-top: -68px;
    left: 50%;
    transform: translateX(-50%);
    .Button {
      min-width: 191px;
    }
  }
  &-template {
    padding: 84px 80px 110px;
    background-color: var(--white-0);
    border: 1px solid var(--gray-30);
    box-shadow: none;
    min-height: 1200px;

    .SectionHeader {
      box-shadow: none;
      border-bottom: 2px solid var(--black-0);
      &.TextComponent {
        background-color: var(--white-0);
      }
    }
  }
}
